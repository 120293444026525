import Img, { ImgProps } from 'components/img/img';
import { graphql } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import './hero.scss';
import parse from 'html-react-parser';
import classNames from 'classnames';
import { TransLink } from 'components/trans/transLink';
import gsap from 'gsap';

export interface HeroProps {
  headline?: string;
  text?: string;
  headlineTight?: number;
  headlineCenter?: boolean;
  image?: ImgProps;
  video?: {
    desktop: {
      sourceUrl: string;
      poster: ImgProps;
    };
    mobile: {
      sourceUrl: string;
      poster: ImgProps;
    };
  };
  mediaPosition?: {
    left: number;
    top: number;
  };
  pageLink?: {
    link?: string;
    slug?: string;
  };
  pageLinkLabel?: string;
}

interface Props extends HeroProps {
  sliderItem?: boolean;
  withDots?: boolean;
  isInActive?: boolean;
}

const Hero: React.FC<Props> = (props) => {
  const [inViewport, setInViewport] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const [dotRand, setDotRand] = useState<{ left: number; right: number }>({
    left: 0,
    right: 0,
  });

  const isMobile = typeof window !== 'undefined' && window.innerWidth < 768;

  const headlineTagName = props.sliderItem ? 'h2' : 'h1';
  const HeadlineTag = headlineTagName as keyof JSX.IntrinsicElements;

  useEffect(() => {
    setDotRand({
      left: Math.random() * -200 - 100,
      right: Math.random() * 180 - 100,
    });
  }, [props.withDots]);

  useEffect(() => {
    const handleScroll = () => {
      const _inViewport = window.scrollY <= window.innerHeight;
      setInViewport(_inViewport);
    };
    if (!!videoRef.current) {
      handleScroll();
      window.addEventListener('scroll', handleScroll);
    }
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const video = videoRef.current;
    if (!!video) {
      if (inViewport && !props.isInActive && video.paused) {
        const promise = video.play();
        !!promise && promise.then(() => console.log('video playback success')).catch(() => console.log('video playback failed'));
      } else if ((!inViewport || props.isInActive) && !video.paused) {
        video.pause();
      }
    }
  }, [inViewport, props.isInActive]);

  return (
    <div
      className={classNames('hero', { 'is-center': props.headlineCenter, 'is-darken': !!props.headline })}
      data-preload={!props.sliderItem && true}
    >
      {/* IMAGE */}
      <div
        className="hero-media"
        style={{
          objectPosition: `${(!!props.mediaPosition && props.mediaPosition.left) || 50}% ${
            (!!props.mediaPosition && props.mediaPosition.top) || 50
          }%`,
        }}
      >
        {!!props.image && <Img {...props.image} className="hero-media__image" />}
        {!!props.video && props.video.desktop.sourceUrl && !!props.video.desktop.poster && !props.image && (
          <video
            src={isMobile && !!props.video.mobile.sourceUrl ? props.video.mobile.sourceUrl : props.video.desktop.sourceUrl}
            playsInline
            muted
            loop
            autoPlay={false}
            poster={
              isMobile && !!props.video.mobile.poster && !!props.video.mobile.poster.localFile
                ? props.video.mobile.poster.localFile.publicURL
                : !!props.video.desktop.poster.localFile
                ? props.video.desktop.poster.localFile.publicURL
                : undefined
            }
            ref={videoRef}
            className="hero-media__video"
          />
        )}
      </div>
      <div className="hero__inner">
        <div className="hero__container">
          <div className="hero__container-box" style={{ width: `${props.headlineTight || 100}%` }}>
            {!!props.headline && (
              <HeadlineTag
                className="hero-title h1"
                dangerouslySetInnerHTML={{
                  __html: props.headline
                    .split(' ')
                    .map((word) => (word.length > 15 ? `<span class="hyphens-auto">${word}</span>` : word))
                    .join(' '),
                }}
              />
            )}
            {!!props.text && <div className="hero-text">{!!props.text && parse(props.text)}</div>}
            {!!props.sliderItem && (
              <TransLink
                to={props.pageLink ? props.pageLink.link || `/agenda/#${props.pageLink.slug}` || '#' : '#'}
                className="hero-link link-button"
              >
                {props.pageLinkLabel || 'Weiterlesen'}
              </TransLink>
            )}
          </div>
        </div>
      </div>
      {!!props.withDots && (
        <>
          <div className="hero-dot is-left">
            <div className="hero-dot__inner" style={{ transform: `translate(-65%, ${dotRand.left}%)` }} />
          </div>
          <div className="hero-dot is-right">
            <div className="hero-dot__inner" style={{ transform: `translate(-65%, ${dotRand.right}%)` }} />
          </div>
        </>
      )}

      {!props.sliderItem && (
        <div className="hero-arrow">
          <div
            className="hero-arrow__inner"
            onClick={() => {
              gsap.to(window, {
                duration: 0.4,
                scrollTo: window.innerHeight,
              });
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Hero;

export const query = graphql`
  fragment HeroBlock on WpAcfHeroBlock {
    hero {
      headline
      text
      headlineTight
      headlineCenter
      image {
        id
        ...BGImage
      }
      mediaPosition {
        left
        top
      }
      video {
        desktop {
          sourceUrl
          poster {
            ...BGImage
          }
        }
        mobile {
          sourceUrl
          poster {
            ...BGImage
          }
        }
      }
    }
  }
`;
